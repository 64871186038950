// Slider.js
import React, { useRef } from "react";
import SlideOne from "./slides/SlideOne";
import SlideTwo from "./slides/SlideTwo";
import SlideThree from "./slides/SlideThree";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

function Sliders() {
    const swiperRef = useRef(null);

    const goToSlide = (index) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(index);
        }
    };

    return (
        <Swiper
            ref={swiperRef}
            spaceBetween={50}
            slidesPerView={1}
            loop={false}
        >
            <SwiperSlide>
                <SlideOne prev={() => goToSlide(2)} next={() => goToSlide(1)}/>
            </SwiperSlide>
            <SwiperSlide>
                <SlideTwo prev={() => goToSlide(0)} next={() => goToSlide(2)}/>
            </SwiperSlide>
            <SwiperSlide>
                <SlideThree prev={() => goToSlide(1)} next={() => goToSlide(0)}/>
            </SwiperSlide>
        </Swiper>
    );
}

export default Sliders;
